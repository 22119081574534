export default {
  getMemberPlanStatus: (state) => state.memberPlanStatus,
  getUserRole: (state) => state.userRole,
  getCurrentCourse: (state) => state.currentCourse,
  getPlayerStatus: (state) => state.playerStatus,
  getPlayerConfig: (state) => state.playerConfig,
  getCurrentModules: (state) => state.currentModules,
  getCurrentModule: (state) => state.currentModule,
  getCurrentModuleLessons: (state) => state.currentModuleLessons,
  getCurrentLesson: (state) => state.currentLesson,
  getCurrentLessonComments: (state) => state.currentLessonComments,
  // 
  getLessonCommentsLoading: (state) => state.lessonCommentsLoading,
  getLessonCommentsCurrentPage: (state) => state.lessonCommentsCurrentPage,
  getLessonCommentstotalPage: (state) => state.lessonCommentstotalPage,
  // 
  getPrevLesson: (state) => state.prevLesson,
  getNextLesson: (state) => state.nextLesson,
  getNextLessonForContinue: (state) => state.nextLessonForContinue,
  getNextModule: (state) => state.nextModule,
  getPrevModule: (state) => state.prevModule,
  getHasNextLesson: (state) => state.hasNextLesson,
  getHasNextModule: (state) => state.hasNextModule,
  getHasPrevModule: (state) => state.hasPrevModule,
  getHasPrevLesson: (state) => state.hasPrevLesson,
  getHasNextLessonForContinue: (state) => state.hasNextLessonForContinue,
  getIsMobile: (state) => state.isMobile,
};
import Cookies from "js-cookie";
import router from '@/router/index';
import MetaService from "@/services/resources/MetaService";
import MemberService from "@/services/resources/MemberService";
import CertifyService from "@/services/resources/CertifyService";
const serviceMember = MemberService.build();

export default {
    getMetasSite: ({
        commit,
        dispatch
    }) => {
        const serviceMeta = MetaService.build();
        return new Promise((resolve, reject) => {
            serviceMeta.search("keys[]=onboarding&keys[]=comment_review&keys[]=helper_data&keys[]=main_color&keys[]=logo&keys[]=theme_fixed_painel&keys[]=theme_fixed_painel_color&keys[]=termsOfUseActivated&keys[]=logomarca&keys[]=panel_background_image&keys[]=panel_background_image_mobile")
                .then((resp) => {
                    commit('metasSite', resp);
                    resolve(resp);
                })
                .catch((err) => {
                    reject(err)
                })
        })
    },
    getCertificateConfig: ({
        commit,
        dispatch
    }) => {
        const serviceCertificate = CertifyService.build();
        return new Promise((resolve, reject) => {
            serviceCertificate.read("check-exist")
                .then((resp) => {
                    commit('certificateConfig', resp);
                    resolve(resp);
                })
                .catch((err) => {
                    reject(err)
                })
        })
    },
    getCurrentSiteAndMember: async ({
        commit,
    }) => {
        commit('setAllSites', []);
        commit('currentSite', Cookies.get('currentSite') ? JSON.parse(Cookies.get('currentSite')) : {
            currentMember: {},
            currentSite: {},
        },);
        commit('setCurrentMember', {});
        return await serviceMember.read("/me")
            .then((resp) => {
                commit('setAllSites', resp.member.sites);
                commit('currentSite', resp.current);
                commit('setCurrentMember', resp);
            })
            .finally(() => {
                commit("setInitLoading", false);
            });
    },
    userRequest: ({ dispatch }) => {
        dispatch('redirectUserToHome');
    },
    redirectUserToHome: () => {
        router.go(0);
    }
}
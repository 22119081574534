import middleware from '@/router/middleware';
import authentication from '@/router/middleware/authentication';
import unauthentication from '@/router/middleware/unauthentication';
import checkGlobalToken from '@/router/middleware/checkGlobalToken';

export default [
  {
    path: '/',
    name: 'Login',
    component: () => import('../../views/Login.vue'),
    beforeEnter: middleware([unauthentication]),
  },
  {
    path: '/set-password',
    name: 'Resetar Senha',
    component: () => import('../../views/ResetPassword.vue'),
    beforeEnter: middleware([unauthentication]),
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import('../../views/Home2.vue'),
    beforeEnter: middleware([authentication, checkGlobalToken]),
  },
  {
    path: '/select-site',
    name: 'Selecionar Site',
    component: () => import('../../views/SelectSite.vue'),
    beforeEnter: middleware([unauthentication]),
  },
  // {
  //     path: '/cursos/:curso',
  //     name: 'curso',
  //     component: () =>
  //         import ('../../views/Player.vue'),
  //     beforeEnter: middleware([authentication]),
  //     children: [{
  //         path: ':modulo/',
  //         name: 'modulo',
  //         component: () =>
  //             import ('../../views/Player.vue'),
  //         beforeEnter: middleware([authentication]),
  //         children: [{
  //             path: ':aula/',
  //             name: 'aula',
  //             component: () =>
  //                 import ('../../views/Player.vue'),
  //             beforeEnter: middleware([authentication]),
  //         }]
  //     }]
  // },
  {
    path: '/curso/:curso',
    name: 'curso',
    component: () => import('../../views/Player.vue'),
    beforeEnter: middleware([authentication]),
  },
  {
    path: '/curso/:curso/modulo/:modulo/',
    name: 'modulo',
    component: () => import('../../views/Player.vue'),
    beforeEnter: middleware([authentication]),
  },
  {
    path: '/curso/:curso/modulo/:modulo/aula/:aula',
    name: 'cursos',
    component: () => import('../../views/Player.vue'),
    beforeEnter: middleware([authentication]),
  },
  {
    path: '/config_curso',
    name: 'config_curso',
    component: () => import('../../views/CreateCourse.vue'),
    beforeEnter: middleware([authentication]),
    children: [
      {
        path: ':curso',
        name: 'config_curso_param',
        component: () => import('../../views/CreateCourse.vue'),
        beforeEnter: middleware([authentication]),
      },
    ],
  },
  {
    path: '/config_curso/vendas/:curso/',
    name: 'curso',
    component: () => import('../../views/CreateCourseSale.vue'),
    beforeEnter: middleware([authentication]),
  },
  {
    path: '/config_curso/:curso/module',
    name: 'curso',
    component: () => import('../../views/CreateModule.vue'),
    beforeEnter: middleware([authentication]),
  },
  {
    path: '/config_curso/:curso/module/:module/lesson',
    name: 'curso',
    component: () => import('../../views/CreateLesson.vue'),
    beforeEnter: middleware([authentication]),
  },
  {
    path: '/config_curso/class/:curso',
    name: 'Sala de Aula',
    component: () => import('../../views/CreateClass.vue'),
    beforeEnter: middleware([authentication]),
  },
  {
    path: '/certificates',
    name: 'Certificados',
    component: () => import('../../views/Certificates.vue'),
    beforeEnter: middleware([authentication]),
  },
  {
    path: '/create-certificates/:curso',
    name: 'Certificados',
    component: () => import('../../views/CreateCertificates.vue'),
    beforeEnter: middleware([authentication]),
  },
  {
    path: '/curso/venda/:id',
    name: 'Vender Curso',
    component: () => import('../../views/SaleCourse.vue'),
    beforeEnter: middleware([authentication]),
  },
  {
    path: '/comunidade',
    name: 'Comunidade',
    component: () => import('../../views/Community.vue'),
    beforeEnter: middleware([authentication]),
  },
  {
    path: '/cards',
    name: 'Cards',
    component: () => import('../../views/Cards.vue'),
    beforeEnter: middleware([authentication]),
  },
  {
    path: '/banners',
    name: 'Banner',
    component: () => import('../../views/CreateBanner.vue'),
    beforeEnter: middleware([authentication]),
  },
  {
    path: '/members',
    name: 'Membros',
    component: () => import('../../views/Members.vue'),
    beforeEnter: middleware([authentication]),
  },
  {
    path: '/config_curso/emails/:curso',
    name: 'E-mails',
    component: () => import('../../views/CreateEmail.vue'),
    beforeEnter: middleware([authentication]),
  },
  {
    path: '/minha-conta',
    name: 'Minha conta',
    component: () => import('../../views/MyAccount.vue'),
    beforeEnter: middleware([authentication]),
  },
  {
    path: '/ajuda',
    name: 'Ajuda',
    component: () => import('../../views/Helper.vue'),
    beforeEnter: middleware([authentication]),
  },
  {
    path: '/ajuda/:id',
    name: 'AjudaTicket',
    component: () => import('../../components/V2/TicketComponent.vue'),
    beforeEnter: middleware([authentication]),
  },
  {
    path: '/configuracoes',
    name: 'Meu Painel',
    component: () => import('../../views/MyPainel.vue'),
    beforeEnter: middleware([authentication]),
  },
  {
    path: '/not-project',
    name: 'Sem Projeto',
    component: () => import('../../views/NotProject.vue'),
    beforeEnter: middleware([unauthentication]),
  },
  {
    path: '/config-domain',
    name: 'Configurar Dominio',
    component: () => import('../../views/ConfigDomain.vue'),
    beforeEnter: middleware([unauthentication]),
  },
  {
    path: '/login-to-google/:auth',
    name: 'Login Google',
    component: () => import('../../views/LoginGoogleOff.vue'),
  },
  {
    path: '/redirect-gd/:token/:site_id',
    name: 'Login Google',
    component: () => import('../../views/Redirect.vue'),
  },
  {
    path: '/assinatura',
    name: 'Assinaturas',
    component: () => import('../../views/Signature.vue'),
    beforeEnter: middleware([authentication]),
  },
];

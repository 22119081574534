export default {
  setMemberPlanStatus: (state, payload) => state.memberPlanStatus = payload,
  setIsExceededStorage: (state, payload) => state.isExceededStorage = payload,
  setPlayerStatus: (state, payload) => state.playerStatus = payload,
  setPlayerConfig: (state, payload) => state.playerConfig = payload,
  setCurrentCourse: (state, payload) => state.currentCourse = payload,
  setCurrentModules: (state, payload) => state.currentModules = payload,
  setCurrentModule: (state, payload) => state.currentModule = payload,
  setCurrentModuleLessons: (state, payload) => state.currentModuleLessons = payload,
  setCurrentLesson: (state, payload) => state.currentLesson = payload,
  setResetCurrentLessonComments: (state, payload) => state.currentLessonComments = payload,
  setCurrentLessonComments: (state, payload) => {
    if (payload.length === 0) {
      return;
    }
    let comments = state.currentLessonComments;
    comments = comments.concat(payload);
    comments = comments.filter(
            (item, index, opt) =>
              index === opt.findIndex((t) => t.id === item.id)
          );
    state.currentLessonComments = comments
  },
  // 
  setLessonCommentsLoading: (state, payload) => state.lessonCommentsLoading = payload,
  setLessonCommentsCurrentPage: (state, payload) =>state.lessonCommentsCurrentPage = payload,
  setLessonCommentstotalPage: (state, payload) => state.lessonCommentstotalPage = payload,
  // 
  setNextLesson: (state, payload) => state.nextLesson = payload,
  setNextLessonForContinue: (state, payload) => state.nextLessonForContinue = payload,
  setPrevLesson: (state, payload) => state.prevLesson = payload,
  setPrevModule: (state, payload) => state.prevModule = payload,
  setNextModule: (state, payload) => state.nextModule = payload,
  setHasNextModule: (state, payload) => state.hasNextModule = payload,
  setHasNextLesson: (state, payload) => state.hasNextLesson = payload,
  setHasPrevLesson: (state, payload) => state.hasPrevLesson = payload,
  setHasPrevModule: (state, payload) => state.hasPrevModule = payload,
  setHasNextLessonForContinue: (state, payload) => state.setHasNextLessonForContinue = payload,
  setIsMobile: (state, payload) => state.isMobile = payload,
};
